// _base.scss
// This file should contain the base styles for the app, including the default body styles, HTML element styling (like h1, p, etc.)
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-y: hidden;
  max-height: 100%;
  font-size: inherit;
  font-family: inherit;
  line-height: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}
