/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* // @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */
@import '../assets/fonts/gulim/gulim.scss';
@import '../assets/fonts/sim-hei/sim-hei.scss';
@import '../assets/fonts/sim-sun/sim-sun.scss';
@import '../assets/fonts/dotum/dotum.scss';
@import '../assets/fonts/batang/batang.scss';
@import '../assets/fonts/meiryo/meiryo.scss';
@import '../assets/fonts/ms-mincho/ms-mincho.scss';

@import 'layout';
@import 'variables';
@import 'mixins';
@import 'base';
@import 'typography';
@import 'flex';
@import 'colors';

body{
  position: relative; 
  min-height: 100%; 
  top: 0px !important;
}

* {
  font-family: initial !important;
}

html, body {
  font-family: initial !important;
}
#google_translate_element {
  position: absolute;
  display: none;
}

a.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  opacity: 0;
  position: absolute;
  z-index: -1;
  display: none;
}

.goog-te-gadget {
  display: none;
}

.goog-te-combo {
  width: 15px;
  top: 17px;
  position: absolute;
  height: auto;
  left: 39px;
  opacity: 0;
  cursor: pointer;
  display: none;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  height: 0px !important;
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
  display: none !important;
}

.tabulator {
  position: relative;
  text-align: left;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  font-size: inherit;
  font-family: inherit;
  background: none;
  border-bottom: 0px;
  height: 100%;
}

.tabulator.tabulator-block-select {
  user-select: none;
}

.tabulator.tabulator-ranges .tabulator-cell:not(.tabulator-editing) {
  user-select: none;
}

.tabulator .tabulator-header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid $DarkGray;
  background-color: $GhostWhite;
  color: $SlateGray;
  white-space: nowrap;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  outline: none;
}

.tabulator .tabulator-header.tabulator-header-hidden {
  display: none;
}

.tabulator .tabulator-header .tabulator-header-contents {
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-header-contents .tabulator-headers {
  display: inline-block;
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid $DarkGray;
  background: inherit;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  position: absolute;
  border: 1px solid $WhiteSmoke;
  background: $LightGray;
  pointer-events: none;
}

.tabulator .tabulator-header .tabulator-col.tabulator-range-highlight {
  background-color: $LightGray;
  color: $Black;
}

.tabulator .tabulator-header .tabulator-col.tabulator-range-selected {
  background-color: $CornflowerBlue;
  color: $White;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  color: $Black;
  bottom: 0;
  height: fit-content;
  padding: 0px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-popup-button {
  padding: 0 8px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-popup-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title-holder {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  box-sizing: border-box;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  padding: 0px !important;
  margin-right: $marginRight
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title.tabulator-col-title-wrap {
  white-space: normal;
  text-overflow: initial;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-title-editor {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $WhiteSmoke;
  padding: 1px;
  background: $White;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-header-popup-button+.tabulator-title-editor {
  width: calc(100% - 22px);
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter {
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  position: relative;
  display: flex;
  border-top: 1px solid $DarkGray;
  overflow: hidden;
  margin-right: -1px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  position: relative;
  box-sizing: border-box;
  margin-top: 2px;
  width: 100%;
  text-align: center;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input::-ms-clear {
  width: 0;
  height: 0;
}



@media (hover: hover) and (pointer: fine) {
  .tabulator .tabulator-header .tabulator-col.tabulator-sortable.tabulator-col-sorter-element:hover {
    cursor: pointer;
    background-color: $LightGray;
  }
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='none'] .tabulator-col-content .tabulator-col-sorter {
  color: $Silver;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='none'] .tabulator-col-content .tabulator-col-sorter.tabulator-col-sorter-element .tabulator-arrow:hover {
    cursor: pointer;
    border-bottom: 6px solid $DimGray;
  }
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='none'] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid $Silver;
}

// Styling for filter error message
.tabulator-headers[role='row'] {
  display: inline-flex !important;
  height: auto !important;
}

// Styling for filter error message
.tabulator-col.tabulator-sortable.tabulator-col-sorter-element {
  height: auto !important;
}

/* Styling for filter error message on edit dd dialogue */
.tabulator-headers .tabulator-col {
  height: auto !important;
}


.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='ascending'] .tabulator-col-content .tabulator-col-sorter {
  color: $MediumSlateBlue;
}

[aria-sort='ascending'] .sorter:hover,
[aria-sort='descending'] .sorter:hover {
  color: $MediumSlateBlue;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='ascending'] .tabulator-col-content .tabulator-col-sorter.tabulator-col-sorter-element .tabulator-arrow:hover {
    cursor: pointer;
    border-bottom: 6px solid $MediumSlateBlue;
  }
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='ascending'] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid $MediumSlateBlue;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='descending'] .tabulator-col-content .tabulator-col-sorter {
  color: $MediumSlateBlue;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='descending'] .tabulator-col-content .tabulator-col-sorter.tabulator-col-sorter-element .tabulator-arrow:hover {
    cursor: pointer;
    border-top: 6px solid $MediumSlateBlue;
  }
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort='descending'] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-bottom: none;
  border-top: 6px solid $MediumSlateBlue;
  color: $MediumSlateBlue;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical .tabulator-col-content .tabulator-col-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  align-items: center;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip .tabulator-col-title {
  transform: rotate(180deg);
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-title {
  padding-right: 0;
  padding-top: 20px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip .tabulator-col-title {
  padding-right: 0;
  padding-bottom: 20px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-sorter {
  justify-content: center;
  left: 0;
  right: 0;
  top: 4px;
  bottom: auto;
}

.tabulator .tabulator-header .tabulator-frozen {
  position: sticky;
  left: 0;
  z-index: 11;
  background-color: $GhostWhite;

}

.tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-left {

  display: flex !important;
  border-right: $marginRight solid $Black;

}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 1px solid $WhiteSmoke;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 1px solid $WhiteSmoke;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  display: inline-block;
  background: $WhiteSmoke !important;
  border-top: 1px solid $DarkGray;
  border-bottom: 1px solid $DarkGray;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: $WhiteSmoke !important;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  display: inline-block;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableholder:focus {
  outline: none;
}

.tabulator .tabulator-tableholder .tabulator-placeholder {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  width: 100%;
}

.tabulator .tabulator-tableholder .tabulator-placeholder[tabulator-render-mode='virtual'] {
  min-height: 100%;
}

.tabulator .tabulator-tableholder .tabulator-placeholder .tabulator-placeholder-contents {
  display: inline-block;
  text-align: center;
  padding: 10px;
  color: $LightGray;


  white-space: normal;
}

.tabulator .tabulator-tableholder .tabulator-table {
  position: relative;
  display: inline-block;
  background-color: $White;
  white-space: nowrap;
  color: $DarkSlateGray;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs {

  background: $Gainsboro !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid $DarkGray;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid $DarkGray;
}

.tabulator .tabulator-tableholder .tabulator-range-overlay {
  position: absolute;
  inset: 0;
  z-index: 10;
  pointer-events: none;
}

.tabulator .tabulator-tableholder .tabulator-range-overlay .tabulator-range {
  position: absolute;
  box-sizing: border-box;
  border: 1px solid $DodgerBlue;
}

.tabulator .tabulator-tableholder .tabulator-range-overlay .tabulator-range.tabulator-range-active::after {
  content: '';
  position: absolute;
  right: -3px;
  bottom: -3px;
  width: 6px;
  height: 6px;
  background-color: $DodgerBlue;
  border-radius: 999px;
}

.tabulator .tabulator-tableholder .tabulator-range-overlay .tabulator-range-cell-active {
  position: absolute;
  box-sizing: border-box;
  border: 2px solid $DodgerBlue;
}

.tabulator .tabulator-footer {
  border-top: 1px solid $DarkGray;
  background-color: $Gainsboro;
  color: $DimGray;

  white-space: nowrap;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator .tabulator-footer .tabulator-footer-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.tabulator .tabulator-footer .tabulator-footer-contents:empty {
  display: none;
}

.tabulator .tabulator-footer .tabulator-spreadsheet-tabs {
  margin-top: -5px;
  overflow-x: auto;
}

.tabulator .tabulator-footer .tabulator-spreadsheet-tabs .tabulator-spreadsheet-tab {
  display: inline-block;
  padding: 5px;
  border: $DarkGray 1px solid;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tabulator .tabulator-footer .tabulator-spreadsheet-tabs .tabulator-spreadsheet-tab:hover {
  cursor: pointer;
  opacity: 0.7;
}

.tabulator .tabulator-footer .tabulator-spreadsheet-tabs .tabulator-spreadsheet-tab.tabulator-spreadsheet-tab-active {
  background: $White;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  background: $WhiteSmoke !important;
  border-bottom: 1px solid $DarkGray;
  border-top: 1px solid $DarkGray;
  overflow: hidden;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  display: inline-block;
  background: $WhiteSmoke !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  margin-bottom: -5px;
  border-bottom: none;
}

.tabulator .tabulator-footer>*+.tabulator-page-counter {
  margin-left: 10px;
}

.tabulator .tabulator-footer .tabulator-paginator {
  flex: 1;
  text-align: right;
  color: $DimGray;
  font-family: inherit;

}

.tabulator .tabulator-footer .tabulator-page-size {
  display: inline-block;
  margin: 0 5px;
  padding: 2px 5px;
  border: 1px solid $DarkGray;
  border-radius: 3px;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  display: inline-block;
  margin: 0 2px;
  padding: 2px 5px;
  border: 1px solid $DarkGray;
  border-radius: 3px;
  background: $White;
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: $OrangeRed;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
  opacity: 0.5;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator .tabulator-footer .tabulator-page:not(disabled):hover {
    cursor: pointer;
    background: $White;
    color: $White;
  }
}


.tabulator .tabulator-alert {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: $Black;
  text-align: center;
}

.tabulator .tabulator-alert .tabulator-alert-msg {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
  background: $White;

}

.tabulator .tabulator-alert .tabulator-alert-msg.tabulator-alert-state-msg {
  border: 4px solid $DarkSlateGray;
  color: $Black;
}

.tabulator .tabulator-alert .tabulator-alert-msg.tabulator-alert-state-error {
  border: 4px solid $OrangeRed;
  color: $Maroon;
}

.tabulator-row {
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid $WhiteSmoke;
  color: $Black;
  background-color: $White;
  height: fit-content;
  min-height: 12px;
  display: flex;
}

.tabulator-row.tabulator-row-even {
  background-color: $White;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-row.tabulator-selectable:hover {
    background-color: $Silver;
    cursor: pointer;
  }
}

.tabulator-row.tabulator-selected {
  background-color: $LightSkyBlue;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-row.tabulator-selected:hover {
    background-color: $SteelBlue;
    cursor: pointer;
  }
}

.tabulator-row.tabulator-row-moving {
  border: 1px solid $Black;
  background: $White;
}

.tabulator-row.tabulator-moving {
  position: absolute;
  border-top: 1px solid $DarkGray;
  border-bottom: 1px solid $DarkGray;
  pointer-events: none;
  z-index: 15;
}

.tabulator-row.tabulator-range-highlight .tabulator-cell.tabulator-range-row-header {
  background-color: $LightGray;
  color: $Black;
}

.tabulator-row.tabulator-range-highlight.tabulator-range-selected .tabulator-cell.tabulator-range-row-header {
  background-color: $CornflowerBlue;
  color: $White;
}

.tabulator-row.tabulator-range-selected .tabulator-cell.tabulator-range-row-header {
  background-color: $CornflowerBlue;
  color: $White;
}

.edit-dd-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $White;
  border: 1px solid $LightGray;
  z-index: 1000;
  overflow: hidden;
  box-sizing: border-box;
  width: auto;
  max-width: 90%;
  height: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  min-height: 100px;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, .32);
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: $Black !important;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-row .tabulator-row-resize-handle:hover {
    cursor: ns-resize;
  }
}

.tabulator-row .tabulator-responsive-collapse {
  box-sizing: border-box;
  padding: 5px;
  border-top: 1px solid $DarkGray;
  border-bottom: 1px solid $DarkGray;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  display: inline-block;
  box-sizing: border-box;
  border-right: 1px solid $WhiteSmoke;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  vertical-align: middle;
  height: auto !important;
  min-height: 11px;
  padding: 0px;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
  position: relative;
}

.tabulator-row .tabulator-cell.tabulator-row-header {
  border-right: 1px solid $DarkGray;
  border-bottom: 1px solid $DarkGray;
  background: $Gainsboro;
}

.tabulator-cell {
  &.wrap-text {
    white-space: unset !important;
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    text-overflow: ellipsis;
  }
  &.wrap-text {
    text-wrap-mode: wrap !important;
  }
  &.nowrap-text {
    text-wrap-mode: nowrap !important;
  }
}

.tabulator-row .tabulator-cell.tabulator-frozen {
  display: inline-block;
  position: sticky;
  left: 0;
  background-color: inherit;
  z-index: 11;
  display: flex;
}

.tabulator-frozen-left {
  border-right: 1px solid $WhiteSmoke;
}

.tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid $WhiteSmoke;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid $RoyalBlue;
  outline: none;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input,
.tabulator-row .tabulator-cell.tabulator-editing select {
  border: 1px;
  background: transparent;
  outline: none;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid $Red;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input,
.tabulator-row .tabulator-cell.tabulator-validation-fail select {
  border: 1px;
  background: transparent;
  color: $Red;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  margin-top: 2px;
  background: $DimGray;
}

.tabulator-row .tabulator-cell.tabulator-range-selected:not(.tabulator-range-only-cell-selected):not(.tabulator-range-row-header) {
  background-color: $LightSkyBlue;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 11px;
  margin-right: 5px;
  border: 1px solid $DarkSlateGray;
  border-radius: 2px;
  background: $Black;
  overflow: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
    cursor: pointer;
    background: $Black;
  }
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: '';
  left: -3px;
  top: 3px;
  height: 1px;
  width: 1rem;
  background: $DarkSlateGray;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: $DarkSlateGray;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: '';
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: $DarkSlateGray;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: $DimGray;
  color: $White;

}

@media (hover: hover) and (pointer: fine) {
  .tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-open {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle svg {
  stroke: $White;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-traffic-light {
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 14px;
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  border-bottom: 1px solid $DarkGray;
  border-right: 1px solid $DarkGray;
  border-top: 1px solid $DarkGray;
  padding: 5px;
  padding-left: 10px;
  background: $LightGray;

  min-width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-row.tabulator-group:hover {
    cursor: pointer;
    background-color: $Black;
  }
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  margin-right: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $DimGray;
  border-bottom: 0;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
  padding-left: 30px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
  padding-left: 50px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
  padding-left: 70px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
  padding-left: 90px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 {
  padding-left: 110px;
}

.tabulator-row.tabulator-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  display: inline-block;
  margin-right: 16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  border-left: 6px solid $DimGray;
  vertical-align: middle;
}

.tabulator-row.tabulator-group span {
  margin-left: 10px;
  color: $OrangeRed;
}

.tabulator-toggle {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border: 1px solid $LightGray;
  background: $Gainsboro;
}

.tabulator-toggle.tabulator-toggle-on {
  background: $CornflowerBlue;
}

.tabulator-toggle .tabulator-toggle-switch {
  box-sizing: border-box;
  border: 1px solid $LightGray;
  background: $White;
}

.tabulator-popup-container {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  background: $White;
  border: 1px solid $DarkGray;
  box-shadow: 0 0 5px 0 $Black;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
}

.tabulator-popup {
  padding: 5px;
  border-radius: 3px;
}

.tabulator-tooltip {
  max-width: Min(500px, 100%);
  padding: 3px 5px;
  border-radius: 2px;
  box-shadow: none;

  pointer-events: none;
}

.tabulator-menu .tabulator-menu-item {
  position: relative;
  box-sizing: border-box;
  padding: 5px 10px;
  user-select: none;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-disabled {
  opacity: 0.5;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-menu .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
    cursor: pointer;
    background: $WhiteSmoke;
  }
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu {
  padding-right: 25px;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu::after {
  display: inline-block;
  position: absolute;
  top: calc(5px + 0.4em);
  right: 10px;
  height: 7px;
  width: 7px;
  content: '';
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: $DarkGray;
  vertical-align: top;
  transform: rotate(45deg);
}

.tabulator-menu .tabulator-menu-separator {
  border-top: 1px solid $DarkGray;
}

.tabulator-edit-list {
  max-height: 200px;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.tabulator-edit-list .tabulator-edit-list-item {
  padding: 4px;

  color: $DarkSlateGray;
  outline: none;
}

.tabulator-edit-list .tabulator-edit-list-item.active {
  color: $White;
  background: $RoyalBlue;
}

.tabulator-edit-list .tabulator-edit-list-item.active.focused {
  outline: 1px solid $White;
}

.tabulator-edit-list .tabulator-edit-list-item.focused {
  outline: 1px solid $RoyalBlue;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-edit-list .tabulator-edit-list-item:hover {
    cursor: pointer;
    color: $White;
    background: $RoyalBlue;
  }
}

.tabulator-edit-list .tabulator-edit-list-placeholder {
  padding: 4px;
  color: $DarkSlateGray;
  text-align: center;
}

.tabulator-edit-list .tabulator-edit-list-group {
  border-bottom: 1px solid $DarkGray;
  padding: 4px;
  padding-top: 6px;
  color: $DarkSlateGray;

}

.tabulator-edit-list .tabulator-edit-list-item.tabulator-edit-list-group-level-2,
.tabulator-edit-list .tabulator-edit-list-group.tabulator-edit-list-group-level-2 {
  padding-left: 12px;
}

.tabulator-edit-list .tabulator-edit-list-item.tabulator-edit-list-group-level-3,
.tabulator-edit-list .tabulator-edit-list-group.tabulator-edit-list-group-level-3 {
  padding-left: 20px;
}

.tabulator-edit-list .tabulator-edit-list-item.tabulator-edit-list-group-level-4,
.tabulator-edit-list .tabulator-edit-list-group.tabulator-edit-list-group-level-4 {
  padding-left: 28px;
}

.tabulator-edit-list .tabulator-edit-list-item.tabulator-edit-list-group-level-5,
.tabulator-edit-list .tabulator-edit-list-group.tabulator-edit-list-group-level-5 {
  padding-left: 36px;
}

.tabulator.tabulator-ltr {
  direction: ltr;
}

.tabulator.tabulator-rtl {
  text-align: initial;
  direction: rtl;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col {
  text-align: initial;
  border-left: 1px solid $DarkGray;
  border-right: initial;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  margin-right: initial;
  margin-left: -1px;
}


.tabulator.tabulator-rtl .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter {
  left: 8px;
  right: initial;
}

.tabulator.tabulator-rtl .tabulator-tableholder .tabulator-range-overlay .tabulator-range.tabulator-range-active::after {
  content: '';
  position: absolute;
  left: -3px;
  right: initial;
  bottom: -3px;
  width: 6px;
  height: 6px;
  background-color: $DodgerBlue;
  border-radius: 999px;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell {
  border-right: initial;
  border-left: 1px solid $DarkGray;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  margin-right: initial;
  margin-left: 5px;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: 1px;
  border-left: initial;
  border-right: 2px solid $DarkGray;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-control {
  margin-right: initial;
  margin-left: 5px;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-left {
  border-left: 2px solid $DarkGray;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-right {
  border-right: 2px solid $DarkGray;
}

.tabulator.tabulator-rtl .tabulator-footer .tabulator-calcs-holder {
  text-align: initial;
}

.tabulator-print-fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

body.tabulator-print-fullscreen-hide>*:not(.tabulator-print-fullscreen) {
  display: none !important;
}

.tabulator-print-table {
  border-collapse: collapse;
}

.tabulator-print-table .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
  border-bottom-left-radius: 1px;
  border-left: 2px solid $DarkGray;
  border-bottom: 2px solid $DarkGray;
}

.tabulator-print-table .tabulator-print-table-group {
  box-sizing: border-box;
  border-bottom: 1px solid $DarkGray;
  border-right: 1px solid $DarkGray;
  border-top: 1px solid $DarkGray;
  padding: 5px;
  padding-left: 10px;
  background: $LightGray;

  min-width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-print-table .tabulator-print-table-group:hover {
    cursor: pointer;
    background-color: $Black;
  }
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-visible .tabulator-arrow {
  margin-right: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $DimGray;
  border-bottom: 0;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-1 td {
  padding-left: 30px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-2 td {
  padding-left: 50px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-3 td {
  padding-left: 70px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-4 td {
  padding-left: 90px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-5 td {
  padding-left: 110px !important;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-arrow {
  display: inline-block;
  margin-right: 16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  border-left: 6px solid $DimGray;
  vertical-align: middle;
}

.tabulator-print-table .tabulator-print-table-group span {
  margin-left: 10px;
  color: $OrangeRed;
}

.tabulator-print-table .tabulator-data-tree-control {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 11px;
  margin-right: 5px;
  border: 1px solid $DarkSlateGray;
  border-radius: 2px;
  background: $Black;
  overflow: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .tabulator-print-table .tabulator-data-tree-control:hover {
    cursor: pointer;
    background: $Black;
  }
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: '';
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: $DarkSlateGray;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: $DarkSlateGray;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: '';
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: $DarkSlateGray;
}

.font-style {
  margin-bottom: $marginBottom;
  text-align: left;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

.modal-backdrop {
  position: relative !important;
}

.modal.show .modal-dialog {
  width: max-content !important;
  max-width: fit-content !important;
}

.modal-header {
  padding: 0px;
  margin-bottom: $marginBottom;
  display: flex;
  justify-content: flex-end;
  cursor: move;
  background-color: $White;
}

.modal-header .close {
  padding: 0px;
  margin: 0px;
}

.close {
  float: right;
  color: $Black;
  text-shadow: 0 1px 0 $White;
  opacity: 1;
  margin-right: $marginRight !important;
}

.tabulator-col-content {
  display: block;
  align-items: center;
}

.tabulator-col-sorter {
  display: flex;
  align-items: center;
}

.button-container {
  display: flex;
  padding-right: 0;
  /* Remove right padding */
  overflow: hidden;
  /* Hide any overflow */
  margin-right: $marginRight;
  cursor: pointer;
}

.dropdown-menu-item {

  margin-right: $marginRight;
  margin-bottom: $marginBottom;
}

.dropdown-menu ul li {

  margin-bottom: $marginBottom;
  margin-right: $marginRight;
  cursor: pointer;
  color: $Black;
}

.menu-item-container {
  display: flex;
  margin-right: $marginRight;
}

.tabulator-row:hover {
  background-color: $WhiteSmoke !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content:hover {
  background: $GhostWhite !important;
  /* or use any color you want for the header */
}


.form-card {
  border-radius: 5px;
  width: auto;
  background-color: $White;
  height: 100%;
}

.form-title {
  text-align: center;
  color: $DarkSlateBlue;
  margin-bottom: $marginBottom;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $marginBottom;
  margin-top: $marginTop;

}

.form-group label {
  color: $Black;
  text-align: left;
  text-wrap: nowrap;
  margin-right: $marginRight;
}

.custom-input {
  border-radius: 10px;
  border-radius: 3px;
  border: 0.5px solid;
  opacity: 0px;
  text-align: left;
  width: auto;
}


.required {
  color: $Red;
}

.btn-save {
  background-color: $LightGray;
}

.btn-cancel {
  background-color: $White;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-bottom: $marginBottom;
}

.btn {
  margin: 0 1px;
  padding: 0px 2px;
  border: 1px solid $Black;
  border-radius: 5px;
  cursor: pointer;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  display: none;
}


.tabulator-row .tabulator-cell .tabulator-data-tree-control,
.tabulator-row .tabulator-cell .parent-button,
.tabulator-row .tabulator-cell .child-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 8px !important;
  margin: 0px 2px 0px 0px !important;
  border: none !important;
  border-radius: none !important;
  background: none !important;
  overflow: hidden;
  z-index: 10;
  position: relative;
}

.tabulator-cell .RowHeaderDiv {
  display: flex;
  height: 100%;
}


.menu-bars {
  display: inline-flex;
  height: 17px;
  align-items: center;

  gap: 2px;
  vertical-align: middle;
  cursor: pointer;
}

.menu-bars::-webkit-scrollbar {
  display: none;
}

.Chip {
  display: inline-flex;
  border: 0.5px solid;
  border-radius: 4px;
  text-wrap: nowrap;
  white-space: nowrap;
  background: transparent;
}

.dropdown-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
}

.pick-ddl .mat-mdc-dialog-surface.mdc-dialog__surface {
  max-height: fit-content;
  overflow: hidden;
  min-height: 307px;
}

.currency-list .mat-mdc-menu-content {
  padding: 0px;
}

.cross-icon {
  font-weight: normal;
  cursor: pointer;
  border: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.expand-icon {
  align-items: center;
  display: flex;
  align-self: center;
}

.menu-bar-container {
  display: flex;
  margin-bottom: $marginBottom;
  cursor: pointer;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  flex: 0 0 auto;
}

.menu-bar-container::-webkit-scrollbar {
  display: none;
  /* Hides the scrollbar */
}

.dropdown-container {
  display: flex;
  flex-direction: column;
}

.cdk-overlay-pane {
  .dropdown {
    right: 3px;

    .button-language {
      min-height: 0px;
      padding: 0px;

      margin-right: $marginRight;
      margin-bottom: $marginBottom;

      .mat-mdc-menu-item-text {
        line-height: initial;
        font-size: initial;
      }
    }
  }

  .language-list {
    overflow: hidden;

    .mat-mdc-menu-content {
      .mat-mdc-menu-item {
        padding: 0px;
        min-height: 0px;
        margin-right: $marginRight;
        margin-bottom: $marginBottom;

        .mat-mdc-menu-item-text {
          line-height: initial;
          font-size: initial;
        }
      }
    }
  }

  .view-list {
    overflow: hidden;

    .mat-mdc-menu-content {
      .mat-mdc-menu-item {
        padding: 0px;
        min-height: 0px;
        margin-right: $marginRight;
        margin-bottom: $marginBottom;

        .mat-mdc-menu-item-text {
          line-height: initial;
          font-size: initial;
        }
      }
    }
  }

  .page-list {
    overflow: hidden;

    .mat-mdc-menu-content {
      .mat-mdc-menu-item {
        padding: 0px;
        margin-right: $marginRight;
        margin-bottom: $marginBottom;
        min-height: auto;

        .mat-mdc-menu-item-text {
          line-height: initial;
          font-size: initial;
        }
      }
    }
  }

  .currency-list {
    overflow: hidden;
    font-size: initial;
  }

  .mat-mdc-menu-content {
    padding: 0px;

    .currency-button {
      padding: 0px;
      min-height: 0px;
      margin-right: $marginRight;
      margin-bottom: $marginBottom;

      .mat-mdc-menu-item-text {
        line-height: initial;
        font-size: initial;
      }
    }
  }
}

.checkbox-item {
  display: flex;
  height: 100%;
  overflow: auto;

  .cdk-drop-list {
    height: 100%;
    margin: 1px 6px 3px 0px;
  }
}

.checkbox {
  cursor: pointer;
  color: $Black;
}

.tooltip-container {
  display: flex;
  align-items: center;
  margin-right: $marginRight;

  .expand-input {
    border: none;
    /* Ensures no additional border is added */
    padding: 0px !important;
    /* Overrides any global padding that might affect size */
    height: calc(100% - 2px);
    /* Adjust height to fit within the button's padding, change 2px based on actual padding if necessary */
    box-sizing: border-box;

    /* Ensures padding and border are included in the height */
    &:focus,
    &:focus-visible {
      outline: none;
    }

    &.has-decimal {
      width: 1.6em;
      /* Width when value has decimal part */
    }

    width: 1em;
  }
}

.language-group {
  display: flex;
  flex-direction: row;
  margin-bottom: $marginBottom;

  .fields-container .text-editor-container {
    width: 227px;
  }
}

.container-modal {
  border-radius: 4px;
  width: fit-content;
}

::ng-deep .container-modal .resizable {
  border-radius: 4px;
}

.field-label {
  text-align: left;
  margin-right: $marginRight;
  text-wrap: nowrap;
}

.text-field-item {
  width: 100%;
  border: 0.5px solid $Black;
  min-width: 200px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  background-color: $White;
}

.View-field-item {
  min-width: 200px;
  border: 0.5px solid $Black;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  background-color: $White;
}

.content-container {
  display: flex;
  flex-direction: column;
  margin-bottom: $marginBottom;
}

.button-close-container {
  margin-bottom: $marginBottom;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: $marginBottom;
}

.first-half-width {
  margin-right: $marginRight;
  width: 45%;
}

.second-half-width {
  width: 55%;
}

select {
  width: 100%;
  border: 0.5px solid $Black;
  border-radius: 3px;
  padding: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
}

.first-group {
  align-items: center;
  width: 350px;
}

.input-field {
  width: 100%;
  /* Make sure the input field takes full width */
  box-sizing: border-box;
  /* Include padding and border in width calculation */
  border: 0.5px solid $Black;
}

.tab-outer-container {
  margin-right: $marginRight;
}

.col-container {
  margin-bottom: $marginBottom;
  align-items: center;
  display: flex;
  cursor: pointer;
  overflow: hidden;
}

.checkbox-style {
  margin-top: 0px !important;
  outline: none;
  vertical-align: middle;
  margin-right: $marginRight;
}

.drag-icon {
  margin-right: $marginRight;
}

.main-container {
  min-width: 470px;
}

.select-language {
  width: auto;
  min-width: auto;
  margin-right: $marginRight;
}

label {
  margin: 0px;
}

.label-container {
  margin-right: $marginRight;
}

.item-field-container {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $marginBottom;
}

app-main-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cdk-overlay-connected-position-bounding-box {
  height: 90% !important;
  max-height: 78%;
}

.tabulator-row .tabulator-cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  align-items: center;
  display: flex;
}

.sorter {
  transform: scale(1, 0.5);
}

.hover-enabled:hover {
  color: $DimGray;
}

.collapse-sign {
  position: relative;
  z-index: 1;
  left: -1px;
}

.collapse-signs {
  position: relative;
  z-index: 1;
  left: -1px;
}


.tabulator-cell .SectionRowHeader {
  margin-right: 1px;
  font-family: "SimHei", monospace;
  font-weight: bold;
  font-size: .7em;
}

.row-header-container {
  display: flex;
}

.tabulator-cell .RowHeader {
  margin-right: $marginRight1px;
  font-family: "SimHei", monospace;
  font-weight: bold;
  background-color: $Gold;
  font-size: .7em;
  width: 0.5em;
  min-width: fit-content;
}

div#RowHeaderID {
  display: flex;
  align-items: center;
}

.checkbox:focus {
  outline: none;
}

.tabulator-container {
  height: 100%;
  /* Adjust for any header or footer area */
  overflow: auto;
  /* Prevent scrolling on the container itself */
  width: 100%;
}

.expand-input[type='text'] {
  background: transparent;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.tabulator-row .tabulator-col-resize-handle {
  display: none;
}

input[type="checkbox"] {
  accent-color: $DodgerBlue;
  width: 0.8em;
  height: 0.8em;
  margin-right: 2px;
}

.button-group-container {
  margin-right: $marginRight;
}

.form-width {
  min-width: fit-content;
  margin-right: $marginRight;
}

.header-title {
  text-align: center;
  margin-bottom: $marginBottom;
}

.font-dialog-container {
  // margin-right: $marginRight;
}

.apply-effect-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: $marginBottom;
}

.edit-item-container {
  margin-bottom: $marginBottom;
}

.font-style-container {
  display: flex;
  width: 100%;
  margin-bottom: $marginBottom;
}

.effects-item-container {
  width: 60%;
}

.table-container-font-style {
  margin-bottom: $marginBottom;
}

.font-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-wrap: nowrap;
  margin-right: $marginRight;
}

.table-container-font-style tr {
  margin-bottom: $marginBottom;
}

.dialog-header {
  display: flex;
  justify-content: center;
  cursor: move;
  background-color: $White;
  padding: 0px;
  margin-bottom: $marginBottom;
  height: fit-content;
  align-items: center;
}

.delete-button-container {
  margin-right: $marginRight;
}

.button-group-container {
  margin-right: $marginRight;
}

.close-icon {
  bottom: calc(100% - 0.875em);
  left: calc(100% - 0.875em);
  right: 0;
  cursor: pointer;
  position: absolute;
}

.text-updated {
  display: flex;
  flex-direction: row-reverse;
}

.custom-tooltip {
  border-radius: 3px;
  text-wrap: nowrap;
  background-color: $Ivory;
  color: $Black;
  text-align: left;
  padding: 0;
  box-sizing: content-box;
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 98vw;
  max-height: 90vh;
  border: 1px solid $LightGray;
  line-height: 1;
  z-index: 1000;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s;

  p {
    margin: 0;
  }
}

.tabulator .tabulator-table {
  backface-visibility: hidden;
  will-change: transform;
}

.tabulator .tabulator-col {
  transform: translateZ(0);
  /* Create a new layer for columns */
}

.show-tooltip {
  animation: fadeIn 0.5s forwards;
}

.stay-tooltip {
  animation: stayVisible 8s forwards;
}

.hide-tooltip {
  animation: fadeOut 0.5s forwards;
}

@keyframes stayVisible {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  99% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  99% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.cell-value-container {
  margin-bottom: $marginBottom;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.expand-input[type=number] {
  width: 18px;
  text-align: center;
  background: transparent;

  &:focus,
  &:focus-visible {
    outline: none !important;
  }
}

.expand-icon {
  position: relative;

  .icon {
    position: absolute;
  }

  .counter-btn {
    position: absolute;
    width: 0.5em;
    height: 1em;

    &.inc {
      top: 0.3em;
      bottom: 0.3em;
    }

    &.dec {
      top: 0.1em;
      left: 0.5em;
    }
  }
}

// Validation styling
.required {
  color: $Red;
}

.form-fields {
  min-width: 54%;
}

.custom-input {
  border-radius: 10px;
  border-radius: 3px;
  border: 0.5px solid;
  opacity: 0px;
  text-align: left;
  min-width: 220px;
  max-width: 220px;
}

input.error {
  border: 2px solid $Red;
}

.custom-error-message {
  color: $Red;
}

.custom-success-message {
  color: $Green;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}


.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: transparent;
}

.loader {
  border: 4px solid $Black;
  border-top: 4px solid $Black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.add-underline {
  border-bottom: 1px solid black;
  height: 1.05em;
}