// _typography.scss
// This file contains typography-related styles, such as heading sizes, font weights, line heights, etc.

h1 {
  color: $primary-color;
}

h2 {
  color: $secondary-color;
}

