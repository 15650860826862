/* Display */
.d-flex {
    display: flex;
  }
  .d-inline-flex {
    display: inline-flex;
  }

  /* Flex Direction */
  .flex-row {
    flex-direction: row;
  }
  .flex-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-column-reverse {
    flex-direction: column-reverse;
  }

  /* Flex Wrap */
  .flex-wrap {
    flex-wrap: wrap;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  /* Justify Content */
  .justify-start {
    justify-content: flex-start;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }
  .justify-evenly {
    justify-content: space-evenly;
  }

  /* Align Items */
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }
  .align-center {
    align-items: center;
  }
  .align-baseline {
    align-items: baseline;
  }
  .align-stretch {
    align-items: stretch;
  }

  /* Align Content */
  .align-content-start {
    align-content: flex-start;
  }
  .align-content-end {
    align-content: flex-end;
  }
  .align-content-center {
    align-content: center;
  }
  .align-content-between {
    align-content: space-between;
  }
  .align-content-around {
    align-content: space-around;
  }
  .align-content-stretch {
    align-content: stretch;
  }

  /* Align Self */
  .self-start {
    align-self: flex-start;
  }
  .self-end {
    align-self: flex-end;
  }
  .self-center {
    align-self: center;
  }
  .self-baseline {
    align-self: baseline;
  }
  .self-stretch {
    align-self: stretch;
  }

  /* Flex Grow */
  .flex-grow-0 {
    flex-grow: 0;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }

  /* Flex Shrink */
  .flex-shrink-0 {
    flex-shrink: 0;
  }
  .flex-shrink-1 {
    flex-shrink: 1;
  }

  .flex-shrink-1-1-auto {
    flex: 1 1 auto;
  }

  .flex-shrink-0-1-auto {
    flex: 0 1 auto;
  }

  /* Flex Basis */
  .flex-basis-auto {
    flex-basis: auto;
  }
  .flex-basis-50 {
    flex-basis: 50%;
  }
  .flex-basis-25 {
    flex-basis: 25%;
  }
  .flex-basis-100 {
    flex-basis: 100%;
  }
