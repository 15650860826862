@font-face {
    font-family: 'Arial Narrow Bold';
    src: url('arialnarrow_bold.ttf') format('truetype');
    
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Arial Narrow Bold Italic';
    src: url('arialnarrow_bolditalic.ttf') format('truetype');
    
    font-style: italic;
  }

  @font-face {
    font-family: 'Arial Narrow Italic';
    src: url('arialnarrow_italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  

  @font-face {
    
    src: url('arialnarrow.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  