//Colors Sheet

// Red Colors
$IndianRed: IndianRed;
$LightCoral: LightCoral;
$Salmon: Salmon;
$DarkSalmon: DarkSalmon;
$LightSalmon: LightSalmon;
$Crimson: Crimson;
$Red: Red;
$FireBrick: FireBrick;
$DarkRed: DarkRed;

// Pink Colors
$Pink: Pink;
$LightPink: LightPink;
$HotPink: HotPink;
$DeepPink: DeepPink;
$MediumVioletRed: MediumVioletRed;
$PaleVioletRed: PaleVioletRed;

// Orange Colors
$LightSalmon: LightSalmon;
$Coral: Coral;
$Tomato: Tomato;
$OrangeRed: OrangeRed;
$DarkOrange: DarkOrange;
$Orange: Orange;

// Yellow Colors
$Gold: Gold;
$Yellow: Yellow;
$LightYellow: LightYellow;
$LemonChiffon: LemonChiffon;
$LightGoldenrodYellow: LightGoldenrodYellow;
$PapayaWhip: PapayaWhip;
$Moccasin: Moccasin;
$PeachPuff: PeachPuff;
$PaleGoldenRod: PaleGoldenRod;
$Khaki: Khaki;
$DarkKhaki: DarkKhaki;

// Purple Colors
$Lavender: Lavender;
$Thistle: Thistle;
$Plum: Plum;
$Violet: Violet;
$Orchid: Orchid;
$Fuchsia: Fuchsia;
$Magenta: Magenta;
$MediumOrchid: MediumOrchid;
$MediumPurple: MediumPurple;
$RebeccaPurple: RebeccaPurple; // RebeccaPurple Color was not present in the tool
$BlueViolet: BlueViolet;
$DarkCiolet: DarkCiolet;
$DarkOrchid: DarkOrchid;
$DarkMagenta: DarkMagenta;
$Purple: Purple;
$Indigo: Indigo;
$SlateBlue: SlateBlue;
$DarkSlateBlue: DarkSlateBlue;
$MediumSlateBlue: MediumSlateBlue;

// Green Colors
$GreenYellow: GreenYellow;
$Chartreuse: Chartreuse;
$LawnGreen: LawnGreen;
$Lime: Lime;
$LimeGreen: LimeGreen;
$PaleGreen: PaleGreen;
$LightGreen: LightGreen;
$MediumSpringGreen: MediumSpringGreen;
$SpringGreen: SpringGreen;
$MediumSeaGreen: MediumSeaGreen;
$SeaGreen: SeaGreen;
$ForestGreen: ForestGreen;
$Green: Green;
$DarkGreen: DarkGreen;
$YellowGreen: YellowGreen;
$OliveDrab: OliveDrab;
$Olive: Olive;
$DarkOliveGreen: DarkOliveGreen;
$MediumAquamarine: MediumAquamarine;
$DarkSeaGreen: DarkSeaGreen;
$LightSeaGreen: LightSeaGreen;
$DarkCyan: DarkCyan;
$Teal: Teal;

// Blue Colors
$Aqua: Aqua;
$Cyan: Cyan;
$LightCyan: LightCyan;
$PaleTurquoise: PaleTurquoise;
$Aquamarine: Aquamarine;
$Turquoise: Turquoise;
$MediumTurquoise: MediumTurquoise;
$DarkTurquoise: DarkTurquoise;
$CadetBlue: CadetBlue;
$SteelBlue: SteelBlue;
$LightSteelBlue: LightSteelBlue;
$PowderBlue: PowderBlue;
$LightBlue: LightBlue;
$SkyBlue: SkyBlue;
$LightSkyBlue: LightSkyBlue;
$DeepSkyBlue: DeepSkyBlue;
$DodgerBlue: DodgerBlue;
$CornflowerBlue: CornflowerBlue;
$MediumSlateBlue: MediumSlateBlue;
$RoyalBlue: RoyalBlue;
$Blue: Blue;
$MediumBlue: MediumBlue;
$DarkBlue: DarkBlue;
$Navy: Navy;
$MidnightBlue: MidnightBlue;

// Brown Colors
$Cornsilk: Cornsilk;
$BlanchedAlmond: BlanchedAlmond;
$Bisque: Bisque;
$NavajoWhite: NavajoWhite;
$Wheat: Wheat;
$BurlyWood: BurlyWood;
$Tan: Tan;
$RosyBrown: RosyBrown;
$SandyBrown: SandyBrown;
$Goldenrod: Goldenrod;
$DarkGoldenrod: DarkGoldenrod;
$Peru: Peru;
$Chocolate: Chocolate;
$SaddleBrown: SaddleBrown;
$Sienna: Sienna;
$Brown: Brown;
$Maroon: Maroon;

// White Colors
$White: White;
$Snow: Snow;
$HoneyDew: HoneyDew;
$MintCream: MintCream;
$Azure: Azure;
$AliceBlue: AliceBlue;
$GhostWhite: GhostWhite;
$WhiteSmoke: WhiteSmoke;
$SeaShell: SeaShell;
$Beige: Beige;
$OldLace: OldLace;
$FloralWhite: FloralWhite;
$Ivory: Ivory;
$AntiqueWhite: AntiqueWhite;
$Linen: Linen;
$LavenderBlush: LavenderBlush;
$MistyRose: MistyRose;

// Grey Colors
$Gainsboro: Gainsboro;
$LightGray: LightGray;
$Silver: Silver;
$DarkGray: DarkGray;
$Gray: Gray;
$DimGray: DimGray;
$LightSlateGray: LightSlateGray;
$SlateGray: SlateGray;
$DarkSlateGray: DarkSlateGray;
$Black: Black;