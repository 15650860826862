// _variables.scss

// Colors
$primary-color: #4b3cce;
$secondary-color: #6c757d;
$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;
$light-color: #f8f9fa;
$dark-color: #343a40;

// Font sizes
$font-size-base: 16px;
$font-size-lg: 18px;
$font-size-sm: 14px;

// marign
$marginBottom: 2px;
$marginRight: 2px;
$marginTop : 2px;
$marginLeft: 2px;
$marginRight1px:1px;
